import React, { useState } from "react"
import axios from "axios"

import {
  Layout,
  Container,
  SEO,
  Sheet,
  Input,
  ThinContainer,
  LoadingDot,
} from "../components/common"

import { checkContactInput } from "../utils"

import "./contact.css"

const API_URL = "https://server.gradienta.io"

export default ({
  pageContext: { totalItems, stats, defaultGradient, footerGradient },
}) => {
  const [status, setStatus] = useState({
    sending: false,
    done: false,
  })
  const [body, setBody] = useState({
    name: "",
    email: "",
    message: "",
    done: false,
  })

  const handleInput = e => {
    setBody({
      ...body,
      [e.target.name]: e.target.value,
      done: checkContactInput(e.target.name, e.target.value),
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (body.done) {
      setStatus({ ...status, sending: true })
      const { data } = await axios.post(`${API_URL}/contact`, {
        ...body,
      })
      setStatus({ ...status, sending: false, done: data.success })
    }
  }

  return (
    <Layout
      title="Contact"
      totalItems={totalItems}
      stats={stats}
      defaultGradient={defaultGradient}
      footerGradient={footerGradient}
      noExtraFootPad
    >
      <Container>
        <SEO title="Contact" />
        <Sheet>
          <ThinContainer>
            <div className="contact">
              <p>
                Any kind of suggestions, bug reports, ideas, improvements, use
                case, license confusion, business enquiries, niche project,
                freelance works or some chitchats are welcome.
              </p>
              <p>
                Feel free to write anything, I will try my best to assist you!
              </p>
              <div className="contact-form">
                <form onSubmit={handleSubmit}>
                  <div className="form-input">
                    <Input
                      name="name"
                      type="text"
                      required
                      input={body.name}
                      onChange={handleInput}
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="form-input">
                    <Input
                      name="email"
                      type="email"
                      required
                      input={body.email}
                      onChange={handleInput}
                      placeholder="Your Email Address"
                    />
                  </div>
                  <div className="form-input">
                    <textarea
                      name="message"
                      rows="15"
                      value={body.message}
                      onChange={handleInput}
                      placeholder="Your message..."
                    ></textarea>
                  </div>
                  <button
                    className={
                      status.done ? "done" : body.done ? "working" : "disabled"
                    }
                    type="submit"
                  >
                    {status.done ? (
                      "Thank You!"
                    ) : status.sending ? (
                      <LoadingDot />
                    ) : (
                      "Send Message"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </ThinContainer>
        </Sheet>
      </Container>
    </Layout>
  )
}
